import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Paper,
  Typography,
  Container,
  Alert,
  Card,
  CardContent,
  Chip,
  Modal,
  Box,
  LinearProgress,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Divider,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  ErrorOutline as ErrorIcon,
  Edit as EditIcon,
  Lock as LockIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  CloudUpload as UploadIcon,
  Visibility as ShowIcon,
  VisibilityOff as HideIcon,
  OpenInNew as OpenInNewIcon,
  Share as ShareIcon,
} from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import { Dropbox } from "dropbox";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  updateDoc,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
  onSnapshot,
  deleteDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  color: "text.primary",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const versionsModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxWidth: "90vw",
  bgcolor: "background.paper",
  color: "text.primary",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const LoginView = ({
  email,
  setEmail,
  password,
  setPassword,
  handleLogin,
  loading,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Typography variant="h5" component="h3" sx={{ mb: 2 }}>
        Admin Login
      </Typography>
      {error && (
        <Alert
          icon={<ErrorIcon fontSize="inherit" />}
          severity="error"
          sx={{ mb: 2 }}
        >
          {error}
        </Alert>
      )}
      <form onSubmit={handleLogin}>
        <TextField
          label="Email"
          placeholder="your@email.com"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
          fullWidth
          variant="filled"
          InputProps={{ disableUnderline: true }}
        />
        <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
          <InputLabel htmlFor="password-input">Password</InputLabel>
          <TextField
            placeholder="Your password"
            required
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mb: 2 }}
            fullWidth
            variant="filled"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <HideIcon /> : <ShowIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Button
          variant="outlined"
          type="submit"
          fullWidth
          disabled={loading}
          color="primary"
        >
          {loading ? "Logging in..." : "Log In"}
        </Button>
      </form>
    </>
  );
};

const DashboardView = ({
  videoReviews,
  updateVideoPassword,
  updateVideoTitle,
  addVideoReview,
  deleteVideoReview,
}) => {
  const [editingReview, setEditingReview] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [isPasswordModal, setIsPasswordModal] = useState(false);
  const [isTitleModal, setIsTitleModal] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isVersionModal, setIsVersionModal] = useState(false);
  const [newReviewTitle, setNewReviewTitle] = useState("");
  const [newReviewPassword, setNewReviewPassword] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewReviewPassword, setShowNewReviewPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isVersionsListModal, setIsVersionsListModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  const fileInputRef = useRef(null);
  const db = getFirestore();

  const {
    loading: tokenLoading,
    error: tokenError,
    getValidToken,
  } = useDropboxToken();

  const closeModals = () => {
    setEditingReview(null);
    setNewPassword("");
    setNewTitle("");
    setIsPasswordModal(false);
    setIsTitleModal(false);
    setIsAddModal(false);
    setIsDeleteModal(false);
    setIsVersionModal(false);
    setSelectedFile(null);
    setUploadProgress(0);
    setUploadError("");
  };

  const handleSavePassword = async () => {
    await updateVideoPassword(editingReview.id, newPassword);
    closeModals();
  };

  const handleSaveTitle = async () => {
    await updateVideoTitle(editingReview.id, newTitle);
    closeModals();
  };

  const handleAddReview = async () => {
    await addVideoReview(newReviewTitle, newReviewPassword);
    closeModals();
  };

  const handleDeleteReview = async () => {
    await deleteVideoReview(editingReview.id);
    closeModals();
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      setUploadError("");
    }
  };

  const handleOpenReview = (reviewId) => {
    window.open(`/review/${reviewId}`, "_blank");
  };

  const handleShareReview = (reviewId) => {
    const reviewUrl = `${window.location.origin}/review/${reviewId}`;
    navigator.clipboard
      .writeText(reviewUrl)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadError("Please select a file to upload");
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);
    setUploadError("");
    console.log("Starting upload process..."); // Log start

    try {
      const dbx = await getDropboxClient(getValidToken); // Ensure token is valid here

      try {
          console.log("Verifying Dropbox connection...");
          await dbx.usersGetCurrentAccount(); // Quick check if token works
          console.log("Dropbox connection verified.");
      } catch (authError) {
          console.error("Dropbox authentication/connection check failed:", authError);
          throw new Error(`Dropbox authentication failed: ${authError.message || JSON.stringify(authError.error)}`);
      }

      const reviewId = editingReview.id;
      const versionNumber = (editingReview.versions?.length || 0) + 1;
      // Basic sanitization for filename, replace common problematic chars
      const safeFileName = selectedFile.name.replace(/[\\/?%*:|"<>]/g, '_');
      const path = `/03. Video Review/${reviewId}/v${versionNumber}/${safeFileName}`; // Use sanitized name
      console.log(`Calculated upload path: ${path}`); // Log intended path

      const fileReader = new FileReader();

      fileReader.onload = async () => {
        console.log("FileReader onload triggered.");
        let sessionId = null; // Declare sessionId outside the inner try
        let finalPath = null; // Declare finalPath outside
        let uploadedFileMetadata = null; // Declare metadata outside

        try {
          const fileData = new Uint8Array(fileReader.result);
          const CHUNK_SIZE = 4 * 1024 * 1024; // Consider 8MB or 16MB for efficiency if needed
          const totalChunks = Math.ceil(fileData.length / CHUNK_SIZE);
          console.log(`File size: ${fileData.length} bytes, Chunk size: ${CHUNK_SIZE}, Total chunks: ${totalChunks}`);

          let uploadedBytes = 0;

          // --- Start Session ---
          try {
            console.log("Attempting filesUploadSessionStart...");
            const sessionStart = await dbx.filesUploadSessionStart({
              close: false,
              contents: fileData.slice(0, Math.min(CHUNK_SIZE, fileData.length)),
            });
            sessionId = sessionStart.result.session_id;
            uploadedBytes += Math.min(CHUNK_SIZE, fileData.length);
            setUploadProgress(Math.floor((uploadedBytes / fileData.length) * 100));
            console.log(`Session started: ${sessionId}, Uploaded: ${uploadedBytes}`);
          } catch (sessionError) {
             console.error("Failed filesUploadSessionStart. Raw Error:", sessionError);
             throw new Error(`Failed to start upload session: ${sessionError.message || JSON.stringify(sessionError.error)}`);
          }

          // --- Append Chunks (if more than one) ---
          for (let i = 1; i < totalChunks; i++) {
            const offset = i * CHUNK_SIZE;
            const chunk = fileData.slice(offset, Math.min(offset + CHUNK_SIZE, fileData.length));
            const isLastChunk = i === totalChunks - 1;
            console.log(`Processing chunk ${i + 1}/${totalChunks}. Offset: ${offset}, Size: ${chunk.length}, Is Last: ${isLastChunk}`);

            if (!isLastChunk) { // Only append if not the last chunk
                try {
                    console.log(`Attempting filesUploadSessionAppendV2. Offset: ${uploadedBytes}`);
                    await dbx.filesUploadSessionAppendV2({
                      cursor: { session_id: sessionId, offset: uploadedBytes },
                      close: false,
                      contents: chunk,
                    });
                    uploadedBytes += chunk.length;
                    setUploadProgress(Math.floor((uploadedBytes / fileData.length) * 100));
                    console.log(`Appended chunk ${i + 1}. Total uploaded: ${uploadedBytes}`);
                } catch (appendError) {
                    console.error(`Failed filesUploadSessionAppendV2 for chunk ${i + 1}. Raw Error:`, appendError);
                    throw new Error(`Failed during chunk upload (chunk ${i + 1}): ${appendError.message || JSON.stringify(appendError.error)}`);
                }
            }
          } // End of append loop

          // --- Finish Session ---
          const lastChunkOffset = (totalChunks - 1) * CHUNK_SIZE;
          const lastChunk = fileData.slice(lastChunkOffset);
          console.log(`Attempting filesUploadSessionFinish. Offset: ${uploadedBytes}, Final chunk size: ${lastChunk.length}, Path: ${path}`);

          try {
             const finishResult = await dbx.filesUploadSessionFinish({
               cursor: { session_id: sessionId, offset: uploadedBytes },
               commit: {
                 path: path,
                 mode: "add",
                 autorename: true,
                 mute: false,
               },
               contents: lastChunk, // Provide the last chunk here
             });
             console.log("filesUploadSessionFinish successful. Result:", finishResult); // Log success
             uploadedFileMetadata = finishResult.result;
             finalPath = uploadedFileMetadata.path_display; // Get the actual path
             console.log(`File committed by Dropbox. Final Path: ${finalPath}, Name: ${uploadedFileMetadata.name}`);
             // At this point, the file *should* be visible in Dropbox.
             setUploadProgress(100); // Set progress to 100 only after successful finish

          } catch (finishError) {
             console.error("Failed filesUploadSessionFinish. Raw Error:", finishError); // Log finish failure
             // Try to parse Dropbox specific errors
             let detailedFinishMessage = finishError.message;
             if (finishError.error) {
                 try {
                     const errorDetails = (typeof finishError.error === 'string') ? JSON.parse(finishError.error) : finishError.error;
                     if (errorDetails.error_summary) { detailedFinishMessage = errorDetails.error_summary; }
                     else if (errorDetails.error && errorDetails.error['.tag']) { detailedFinishMessage = `Dropbox API error tag: ${errorDetails.error['.tag']}`; }
                     // Check specific tags relevant to finish failures
                     if (detailedFinishMessage.includes('path/conflict')) { detailedFinishMessage += ` (Conflict at path: ${path})`; }
                     if (detailedFinishMessage.includes('insufficient_space')) { detailedFinishMessage = 'Insufficient space in Dropbox account.'; }
                 } catch(e) { /* Ignore parsing errors */ }
             }
             // **** Throw specific finish error ****
             throw new Error(`Upload commit failed: ${detailedFinishMessage}`);
          }

          // --- Create Shared Link (only if finish succeeded) ---
          if (!finalPath) {
            // This shouldn't happen if finish succeeded, but defensive check
            throw new Error("File upload finished but final path was not obtained.");
          }
          try {
            console.log(`Attempting to create shared link for: ${finalPath}`);
            const shareResponse = await dbx.sharingCreateSharedLinkWithSettings({
              path: finalPath, // Use the final path from finish result
              settings: {
                requested_visibility: "public",
                audience: "public",
                access: "viewer",
              },
            });
            const sharedLink = shareResponse.result.url.replace("&dl=0", "&raw=1");
            console.log("Shared link created successfully:", sharedLink);

            // --- Update Firestore (only if share succeeded) ---
            try {
              console.log("Attempting to update Firestore...");
              const reviewRef = doc(db, "video_reviews", reviewId);
              await updateDoc(reviewRef, {
                versions: arrayUnion({
                  sharedLink,
                  filePath: finalPath, // Store the actual final path
                  fileName: uploadedFileMetadata.name, // Store the actual final name
                  timestamp: new Date().toISOString(),
                  comments: [],
                }),
              });
              console.log("Firestore update successful.");
              setIsUploading(false); // Only set false on full success
              closeModals();       // Only close on full success

            } catch (firestoreError) {
              console.error("Firestore update failed:", firestoreError);
              // CRITICAL: Consider cleanup logic here - delete the uploaded file/link?
              throw new Error(`Database update failed after upload/share: ${firestoreError.message}`);
            }
          } catch (shareError) {
             console.error(`Failed sharingCreateSharedLinkWithSettings for path '${finalPath}'. Raw Error:`, shareError); // Log share failure
             let detailedShareMessage = shareError.message;
             if (shareError.error) { /* ... parse error details ... */ }
             if (detailedShareMessage.includes('shared_link_already_exists')) { detailedShareMessage += ` (A public link might already exist for this exact file/path)`; }
             // CRITICAL: Consider cleanup logic here - delete the uploaded file?
             // **** Throw specific share error ****
             throw new Error(`Failed to create shared link: ${detailedShareMessage}`);
          }
        } catch (uploadProcessError) { // Catch errors from session start, append, finish, share, or Firestore
          console.error("Error during upload/share/db process:", uploadProcessError);
          setUploadError(`Operation failed: ${uploadProcessError.message}`);
          setIsUploading(false); // Ensure loading state is reset on error
          // Do NOT close the modal automatically on error, let the user see the message.
        }
      }; // End fileReader.onload

      fileReader.onerror = (error) => {
        console.error("FileReader error:", error);
        setUploadError(`Failed to read file: ${error.message || "Unknown error"}`);
        setIsUploading(false);
      };

      console.log("Calling fileReader.readAsArrayBuffer...");
      fileReader.readAsArrayBuffer(selectedFile);

    } catch (outerError) { // Catch errors from getDropboxClient or initial setup
      console.error("Initial setup error before reading file:", outerError);
      setUploadError(`Upload setup failed: ${outerError.message}`);
      setIsUploading(false);
    }
};

  const handleDeleteVersion = async (reviewId, versionIndex) => {
    try {
      const reviewRef = doc(db, "video_reviews", reviewId);
      const reviewDoc = await getDoc(reviewRef);

      if (reviewDoc.exists()) {
        const versions = [...reviewDoc.data().versions];
        versions.splice(versionIndex, 1);

        await updateDoc(reviewRef, {
          versions: versions,
        });

        const updatedReview = {
          ...selectedReview,
          versions: versions,
        };
        setSelectedReview(updatedReview);
      }
    } catch (err) {
      setUploadError(`Failed to delete version: ${err.message}`);
    }
  };

  if (tokenError) {
    return (
      <Alert
        severity="error"
        sx={{ mb: 2, display: "flex", alignItems: "center" }}
      >
        Dropbox authentication error: {tokenError}. Please check your Dropbox
        credentials.
      </Alert>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h5" component="h3">
          Video Review Admin
        </Typography>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h6" component="h4">
          Video Reviews
        </Typography>
        <Button
          startIcon={<AddIcon />}
          onClick={() => setIsAddModal(true)}
          color="primary"
          variant="contained"
          size="small"
        >
          Add New Review
        </Button>
      </Box>

      {videoReviews.map((review) => {
        const latestVersion =
          review.versions && review.versions.length > 0
            ? review.versions[review.versions.length - 1]
            : null;
        const commentsCount = latestVersion?.comments?.length || 0;

        return (
          <Card key={review.id} sx={{ mb: 2 }} variant="outlined">
            <CardContent sx={{ pb: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="subtitle1" fontWeight="500">
                  {review.title}
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Tooltip title="Open Review">
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={() => handleOpenReview(review.id)}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Share URL">
                    <IconButton
                      color="info"
                      size="small"
                      onClick={() => handleShareReview(review.id)}
                    >
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Password: {review.password || "None"}
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Chip
                    color="primary"
                    label={`${commentsCount} Comments`}
                    size="small"
                  />
                  <Chip
                    color="success"
                    label={`${review.versions?.length || 0} ${
                      review.versions?.length === 1 ? "Version" : "Versions"
                    }`}
                    size="small"
                    onClick={() => {
                      setSelectedReview(review);
                      setIsVersionsListModal(true);
                    }}
                    sx={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
              <Divider sx={{ mb: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: 2,
                }}
              >
                <Grid xs={3}>
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    startIcon={<UploadIcon />}
                    onClick={() => {
                      setEditingReview(review);
                      setIsVersionModal(true);
                      setSelectedFile(null);
                      setUploadProgress(0);
                      setUploadError("");
                    }}
                    color="primary"
                    sx={{
                      background:
                        "linear-gradient(90deg, #8e24aa 0%, #1976d2 100%)",
                      minWidth: 150,
                      fontSize: "0.75rem",
                    }}
                  >
                    Add Version
                  </Button>
                </Grid>
                <Grid xs={3}>
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    startIcon={<EditIcon />}
                    onClick={() => {
                      setEditingReview(review);
                      setNewTitle(review.title);
                      setIsTitleModal(true);
                    }}
                    color="primary"
                    sx={{ minWidth: 150, fontSize: "0.75rem" }}
                  >
                    Edit Title
                  </Button>
                </Grid>
                <Grid xs={3}>
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    startIcon={<LockIcon />}
                    onClick={() => {
                      setEditingReview(review);
                      setNewPassword(review.password || "");
                      setIsPasswordModal(true);
                    }}
                    color="primary"
                    sx={{ minWidth: 150, fontSize: "0.75rem" }}
                  >
                    {review.password ? "Edit Password" : "Add Password"}
                  </Button>
                </Grid>
                <Grid xs={3}>
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      setEditingReview(review);
                      setIsDeleteModal(true);
                    }}
                    color="error"
                    sx={{ minWidth: 150, fontSize: "0.75rem" }}
                  >
                    Delete
                  </Button>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        );
      })}

      <Modal open={isPasswordModal} onClose={closeModals}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            {`${editingReview?.password ? "Update" : "Add"} Password - ${
              editingReview?.title || ""
            }`}
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
            <TextField
              label="Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              sx={{ mb: 2 }}
              fullWidth
              variant="filled"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <HideIcon /> : <ShowIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText="Leave empty to remove password"
            />
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button variant="outlined" onClick={closeModals} color="inherit">
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={handleSavePassword}
              color="primary"
            >
              Save Password
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={isTitleModal} onClose={closeModals}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Update Title
          </Typography>
          <TextField
            label="New Title"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            sx={{ mb: 2 }}
            fullWidth
            variant="filled"
            InputProps={{ disableUnderline: true }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button variant="outlined" onClick={closeModals} color="inherit">
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={handleSaveTitle}
              color="primary"
            >
              Save Title
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={isAddModal} onClose={closeModals}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Add New Video Review
          </Typography>
          <TextField
            label="Title"
            placeholder="Enter review title"
            value={newReviewTitle}
            onChange={(e) => setNewReviewTitle(e.target.value)}
            sx={{ mb: 2 }}
            required
            fullWidth
            variant="filled"
            InputProps={{ disableUnderline: true }}
          />
          <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
            <TextField
              label="Password (Optional)"
              value={newReviewPassword}
              onChange={(e) => setNewReviewPassword(e.target.value)}
              type={showNewReviewPassword ? "text" : "password"}
              sx={{ mb: 2 }}
              fullWidth
              variant="filled"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowNewReviewPassword(!showNewReviewPassword)
                      }
                      edge="end"
                    >
                      {showNewReviewPassword ? <HideIcon /> : <ShowIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText="Leave empty to remove password"
            />
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button variant="outlined" onClick={closeModals} color="inherit">
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={handleAddReview}
              disabled={!newReviewTitle}
              color="primary"
            >
              Add Review
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={isDeleteModal} onClose={closeModals}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Delete Video Review
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Are you sure you want to delete "{editingReview?.title}"? This
            action cannot be undone.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button variant="outlined" onClick={closeModals} color="inherit">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleDeleteReview}
              color="error"
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={isVersionModal}
        onClose={isUploading && !uploadError ? null : closeModals}
        disableEscapeKeyDown={isUploading && !uploadError}
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            {editingReview?.title || ""} - v
            {(editingReview?.versions?.length || 0) + 1}
          </Typography>

          <input
            type="file"
            accept="video/*"
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={fileInputRef}
          />

          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Button
              onClick={() => fileInputRef.current.click()}
              color="primary"
              startIcon={<UploadIcon />}
              disabled={isUploading && !uploadError}
              variant="contained"
            >
              Select Video File
            </Button>
          </Box>

          {selectedFile && (
            <Typography variant="body2" align="center" sx={{ mb: 2 }}>
              Selected: {selectedFile.name} (
              {Math.round((selectedFile.size / 1024 / 1024) * 10) / 10} MB)
            </Typography>
          )}

          {uploadError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {uploadError}
              <Button
                size="small"
                onClick={() => setUploadError("")}
                sx={{ ml: 1 }}
              >
                Dismiss
              </Button>
            </Alert>
          )}

          {isUploading && !uploadError && (
            <>
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                Upload Progress: {uploadProgress}%
              </Typography>
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                sx={{ mb: 2 }}
              />
            </>
          )}

          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
            <Button
              variant="outlined"
              onClick={closeModals}
              color="inherit"
              disabled={isUploading && !uploadError}
            >
              {uploadError ? "Close" : "Cancel"}
            </Button>
            <Button
              variant="contained"
              onClick={handleUpload}
              color="primary"
              disabled={!selectedFile || (isUploading && !uploadError)}
            >
              {isUploading && !uploadError ? "Uploading..." : "Upload"}
            </Button>
            {uploadError && (
              <Button
                variant="outlined"
                onClick={() => {
                  setUploadError("");
                  setUploadProgress(0);
                }}
                color="secondary"
              >
                Try Again
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      <VersionsModal
        open={isVersionsListModal}
        onClose={() => setIsVersionsListModal(false)}
        review={selectedReview}
        onDeleteVersion={handleDeleteVersion}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
        >
          Review URL copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  );
};

const VideoReviewAdmin = () => {
  const mode = "dark";
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [videoReviews, setVideoReviews] = useState([]);
  const [dropboxSetupOpen, setDropboxSetupOpen] = useState(false);
  const [dropboxStatus, setDropboxStatus] = useState({
    loaded: false,
    valid: false,
  });
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setupRealtimeUpdates();
        checkDropboxSetup();
      }
    });
    return () => unsubscribe();
  }, [auth]);

  const checkDropboxSetup = async () => {
    try {
      const tokenDoc = await getDoc(
        doc(db, "system_settings", "dropbox_token")
      );
      setDropboxStatus({
        loaded: true,
        valid: tokenDoc.exists(),
      });
    } catch (err) {
      console.error("Error checking Dropbox setup:", err);
      setDropboxStatus({ loaded: true, valid: false });
    }
  };

  const setupRealtimeUpdates = () => {
    const videoReviewsQuery = query(
      collection(db, "video_reviews"),
      orderBy("created", "desc")
    );

    return onSnapshot(
      videoReviewsQuery,
      (querySnapshot) => {
        const reviews = [];
        querySnapshot.forEach((doc) => {
          reviews.push({ id: doc.id, ...doc.data() });
        });
        setVideoReviews(reviews);
      },
      (error) => {
        setError("Error getting real-time updates: " + error.message);
      }
    );
  };

  const updateVideoPassword = async (reviewId, newPassword) => {
    try {
      const reviewRef = doc(db, "video_reviews", reviewId);
      await updateDoc(reviewRef, {
        password: newPassword || null,
      });
    } catch (err) {
      setError("Failed to update password: " + err.message);
    }
  };

  const updateVideoTitle = async (reviewId, newTitle) => {
    try {
      const reviewRef = doc(db, "video_reviews", reviewId);
      await updateDoc(reviewRef, {
        title: newTitle,
      });
    } catch (err) {
      setError("Failed to update title: " + err.message);
    }
  };

  const deleteVideoReview = async (reviewId) => {
    try {
      const reviewRef = doc(db, "video_reviews", reviewId);
      await deleteDoc(reviewRef);
    } catch (err) {
      setError("Failed to delete review: " + err.message);
    }
  };

  const addVideoReview = async (title, password) => {
    try {
      const reviewData = {
        title,
        password: password || null,
        versions: [],
        created: serverTimestamp(),
      };

      await addDoc(collection(db, "video_reviews"), reviewData);
    } catch (err) {
      setError("Failed to add new review: " + err.message);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setVideoReviews([]);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bgcolor: "background.default",
          minHeight: "100vh",
          width: "100%",
          pt: 2,
        }}
      >
        <Container maxWidth="md" sx={{ mt: 6 }}>
          <Paper
            elevation={3}
            sx={{
              p: 4,
              borderRadius: 2,
            }}
          >
            {user ? (
              <>
                {dropboxStatus.loaded && !dropboxStatus.valid && (
                  <Alert
                    severity="warning"
                    sx={{ mb: 2, display: "flex", alignItems: "center" }}
                    action={
                      <Button
                        color="inherit"
                        size="small"
                        onClick={() => setDropboxSetupOpen(true)}
                        sx={{ minWidth: 110 }}
                      >
                        Setup Now
                      </Button>
                    }
                  >
                    Dropbox API not configured. Video uploads will not work.
                  </Alert>
                )}
                <DashboardView
                  user={user}
                  handleLogout={handleLogout}
                  videoReviews={videoReviews}
                  updateVideoPassword={updateVideoPassword}
                  updateVideoTitle={updateVideoTitle}
                  addVideoReview={addVideoReview}
                  deleteVideoReview={deleteVideoReview}
                />
                <DropboxSetupModal
                  open={dropboxSetupOpen}
                  onClose={() => {
                    setDropboxSetupOpen(false);
                    checkDropboxSetup();
                  }}
                />
              </>
            ) : (
              <LoginView
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                handleLogin={handleLogin}
                loading={loading}
                error={error}
              />
            )}
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default VideoReviewAdmin;

const useDropboxToken = () => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getFirestore();
  const functions = getFunctions();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const tokenDoc = await getDoc(
          doc(db, "system_settings", "dropbox_token")
        );

        if (tokenDoc.exists()) {
          const storedToken = tokenDoc.data();
          const currentTime = Date.now() / 1000;

          // If token expired or will expire soon, refresh it
          if (
            !storedToken.expiration_time ||
            storedToken.expiration_time - currentTime < 600
          ) {
            const refreshDropboxToken = httpsCallable(
              functions,
              "refreshDropboxToken"
            );
            const result = await refreshDropboxToken();

            setToken({
              ...storedToken,
              access_token: result.data.access_token,
              expiration_time: result.data.expiration_time,
            });
          } else {
            setToken(storedToken);
          }
        } else {
          setError("No Dropbox credentials found. Please set up credentials.");
        }
      } catch (err) {
        setError(`Error fetching token: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchToken();
  }, []);

  const getValidToken = async () => {
    try {
      if (!token) return null;

      const currentTime = Date.now() / 1000;
      if (!token.expiration_time || token.expiration_time - currentTime < 600) {
        const refreshDropboxToken = httpsCallable(
          functions,
          "refreshDropboxToken"
        );
        const result = await refreshDropboxToken();

        const updatedToken = {
          ...token,
          access_token: result.data.access_token,
          expiration_time: result.data.expiration_time,
        };

        setToken(updatedToken);
        return updatedToken;
      }

      return token;
    } catch (error) {
      setError(`Failed to get valid token: ${error.message}`);
      return null;
    }
  };

  return { token, loading, error, getValidToken };
};

const DropboxSetupModal = ({ open, onClose }) => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const functions = getFunctions();

  const handleSetup = async () => {
    try {
      setSaving(true);
      setError("");

      const getDropboxAuthUrl = httpsCallable(functions, "getDropboxAuthUrl");
      const result = await getDropboxAuthUrl();
      console.log(result);
      window.open(result.data.authUrl, "_blank", "width=600,height=700");
      onClose();
    } catch (err) {
      setError(`Failed to initiate setup: ${err.message}`);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography
          variant="h6"
          component="h2"
          sx={{ mb: 2, display: "flex", alignItems: "center" }}
        >
          Dropbox Authorization
        </Typography>

        {error && (
          <Alert
            severity="error"
            sx={{ mb: 2, display: "flex", alignItems: "center" }}
          >
            {error}
          </Alert>
        )}

        <Alert
          severity="info"
          sx={{ mb: 2, display: "flex", alignItems: "center" }}
        >
          Click the button below to authorize this application to access your
          Dropbox account. You'll be redirected to Dropbox to complete the
          authorization process.
        </Alert>

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button variant="outlined" onClick={onClose} color="inherit">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSetup}
            color="primary"
            disabled={saving}
          >
            {saving ? "Initiating..." : "Connect to Dropbox"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const getDropboxClient = async (getValidToken) => {
  const token = await getValidToken();
  if (!token || !token.access_token) {
    throw new Error("No valid Dropbox access token available");
  }
  return new Dropbox({ accessToken: token.access_token });
};

const VersionsModal = ({ open, onClose, review, onDeleteVersion }) => {
  const [deletingVersion, setDeletingVersion] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const handleDeleteClick = (versionIndex) => {
    setDeletingVersion(versionIndex);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (deletingVersion !== null) {
      await onDeleteVersion(review.id, deletingVersion);
      setConfirmDeleteOpen(false);
      setDeletingVersion(null);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "Unknown date";
    try {
      const date = new Date(timestamp);
      return date.toLocaleString();
    } catch (e) {
      return "Invalid date";
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={versionsModalStyle}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Versions - {review?.title || ""}
          </Typography>

          {review?.versions?.length === 0 ? (
            <Typography variant="body1" sx={{ textAlign: "center", my: 2 }}>
              No versions available yet
            </Typography>
          ) : (
            <Box sx={{ maxHeight: "400px", overflowY: "auto" }}>
              {review?.versions?.map((version, index) => (
                <Card key={index} sx={{ mb: 2 }} variant="outlined">
                  <CardContent sx={{ pb: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <Typography variant="subtitle1" fontWeight="500">
                        Version {index + 1}
                      </Typography>
                      <IconButton
                        color="error"
                        size="small"
                        onClick={() => handleDeleteClick(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <Grid container spacing={2} sx={{ mb: 1 }}>
                      <Grid xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          Uploaded: {formatDate(version.timestamp)}
                        </Typography>
                      </Grid>
                      <Grid xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          Comments: {version.comments?.length || 0}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        mt: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={<OpenInNewIcon />}
                        href={version.sharedLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open Video
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button variant="outlined" onClick={onClose} color="inherit">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Confirm Delete
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Are you sure you want to delete Version{" "}
            {deletingVersion !== null ? deletingVersion + 1 : ""}?
            {review?.versions?.[deletingVersion]?.comments?.length > 0 && (
              <Typography color="error" sx={{ mt: 1 }}>
                Warning: This version has{" "}
                {review.versions[deletingVersion].comments.length} comments that
                will also be deleted.
              </Typography>
            )}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button
              variant="outlined"
              onClick={() => setConfirmDeleteOpen(false)}
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirmDelete}
              color="error"
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
